<template>
  <v-container>
    <v-row justify="center">
      <banner/>
    </v-row>
    <v-row justify="center">
      <search-box/>
    </v-row>
    <v-row justify="center">
      <footer-block/>
    </v-row>
  </v-container>
</template>

<script>
import SearchBox from "@/components/IndexSearchBox";
import FooterBlock from "@/components/IndexFooterBlock";
import Banner from "@/components/IndexBanner";

export default {
  name: "Index",
  components: {Banner, SearchBox, FooterBlock}
};
</script>
