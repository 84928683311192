<template>
  <v-card color="background" flat max-width="200px">
    <v-card-text>
      <v-img :src="require('@/assets/logo.png')" alt="StarStart!" center/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Banner",
};
</script>
