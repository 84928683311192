<template>
  <v-card color="background" flat>
    <v-card-text>
      <a :href="localeUrl">Language (English)</a>
      <div id="tag">
        <router-link link to="/privacy">Privacy Policy</router-link>
        |
        <router-link link to="/about">About</router-link>
      </div>
      <div id="copyright">
        &copy; {{ year }} <a href="https://starinc.xyz">Star Inc.</a>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FooterBlock",
  data: () => ({
    localeUrl: process.env.VUE_APP_LOCALE_CENTER_URL
  }),
  computed: {
    year() {
      const now = new Date();
      return now.getFullYear();
    }
  }
}
</script>

<style scoped>
* {
  color: #999 !important;
  text-decoration: none;
  text-align: center;
}
</style>
