<template>
  <v-card color="background" flat>
    <v-card-text>
      <v-text-field
          v-model="question"
          clearable
          hint="Search Provider: Lena"
          @keydown.enter="query"
      >
        <template v-slot:append-outer>
          <v-btn class="primary" @click.prevent="query">Start!</v-btn>
        </template>
      </v-text-field>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SearchBox",
  data: () => ({
    question: null,
  }),
  methods: {
    query() {
      if (!this.question) return;
      this.$router.push({
        path: "/lena",
        query: {
          q: this.question,
        }
      })
    },
  },
};
</script>
